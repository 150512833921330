module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-pinterest/gatsby-browser.js'),
      options: {"plugins":[],"saveButton":{"round":false,"tall":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-49165303-1"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"madisonHouseDesign","short_name":"mhd","start_url":"/","background_color":"#f9f0b0","theme_color":"#f9f0b0","display":"minimal-ui","icon":"src/images/mhd-logo.png","icons":[{"src":"src/images/mhd-logo.png","sizes":"300x300","type":"image/png","purpose":"any"},{"src":"src/images/mhd-logo-big.png","sizes":"600x600","type":"image/png","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8e3c0b582ea4a62e0d0ebfca4b45fe77"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
